import React from "react"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import CardWidget from "../../widgets/cardWidget"

const useStyle = makeStyles(theme => ({
  wrapper: {
    padding: 0,
    border: "none",
    background: "transparent",
    minHeight: 0,
    minWidth: 0,
    cursor: "pointer",

    paddingRight: "0.62vw",
    "@media(min-width: 1280px)": {
      paddingRight: "8px",
    },
    "@media(max-width: 1025px)": {
      paddingRight: "0.95vw",
    },
    "@media(max-width: 767px)": {
      paddingRight: "1.93vw",
    },
  },
}))

/**
 * Набор фильтров по подкатегориям
 * @module src/components/discountedProdicts/filterCategory/filtersBySubcategory
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.products - массив объектов продуктов полученых из prismic
 */
export default function FiltersBySubcategory({ products, setHeaderTitle }) {
  const classes = useStyle()

  // собираем категории отображаемых продуктов
  const categoriesTitle = new Set()
  let categoriesUid = new Set()
  let categoriesBrand = new Set()
  products.forEach(product => {
    if (product?.data.category.document === null) return
    categoriesTitle.add(product?.data.category.document.data.name)
    categoriesUid.add(product?.data.category.uid)
    categoriesBrand.add(product?.data?.brand.uid)
  })
  categoriesBrand = Array.from(categoriesBrand)
  categoriesUid = Array.from(categoriesUid)
  // function filter(value) {
  //   const url = new URL(window.location)

  //   const group = url.searchParams.get("group") ?? false
  //   const search = url.searchParams.get("search") ?? false
  //   const category = url.searchParams.get("category") ?? false

  //   url.search = ""
  //   url.searchParams.set("subcategory", JSON.stringify(value))

  //   if (group) url.searchParams.set("group", group)
  //   if (search) url.searchParams.set("search", search)
  //   if (category) url.searchParams.set("category", category)

  //   window.location = url.href
  //   navigate(`${window.location.pathname}${url.search}`)
  // }

  return (
    <>
      {[...categoriesTitle].map((category, index) => {
        return (
          <button
            onClick={() => {
              if (categoriesBrand.length === 1) {
                navigate(
                  `/catalog/${categoriesUid[index]}/${categoriesBrand[0]}`
                )
              } else {
                navigate(`/catalog/${categoriesUid[index]}`)
              }
              // navigate(`${window.location.pathname}${categoriesUid[index]}`)
              // filter(category)
              setHeaderTitle(category)
            }}
            aria-label={category}
            key={category}
            className={classes.wrapper}
          >
            <CardWidget variant="small" cardTitle={category} />
          </button>
        )
      })}
    </>
  )
}
